<template lang="pug">
div#dev-data-dump.container(v-if="globalState.userRole == 5")
  div.row
    h3 Raw <small class="text-muted">(devs only)</small>
  div.row
    pre: code {{ data }}
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      globalState
    }
  },
  props: {
    data: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
#dev-data-dump {
    margin-top: 50px;

    pre {
        background-color: lightgray;
    }
}
</style>
