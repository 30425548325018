<template lang="pug">
div#app
  b-navbar(toggleable="md" type="dark" variant="primary")
    b-navbar-toggle(target="nav_collapse")
    b-navbar-brand(to="/")
      img.d-inline-block.align-top(src="@/assets/logo.png" height="29" alt="IGDA Finland logo")
      | &nbsp;&nbsp;
      b-badge(variant="warning" v-if="!globalState.isProductionEnv") DEV
    b-collapse(is-nav id="nav_collapse" v-if="globalState.isLoggedIn == true")
      //- Left side of nav
      b-navbar-nav
        b-nav-item(to="/events" v-if="globalState.userRole >= 2")
          fa-icon(icon="calendar-check")
          | &nbsp;
          span Check-in
        b-nav-item(to="/membership-check" v-if="globalState.userRole >= 2")
          fa-icon(icon="user-tag")
          | &nbsp;
          span Memberships

      //- Right side of nav
      b-navbar-nav.ml-auto
        b-nav-item-dropdown(right v-if="globalState.userRole >= 2")
          template(slot="button-content")
            fa-icon(icon="calendar-alt")
            | &nbsp;
            span Events
          b-dropdown-item(to="/events/manage") Manage Events
          b-dropdown-item(to="/events/visitors") Manage Visitors
          b-dropdown-item(to="/events/analytics") Event Analytics

        b-nav-item-dropdown(right v-if="globalState.userRole >= 3")
          template(slot="button-content")
            fa-icon(icon="tags")
            | &nbsp;
            span Members
          b-dropdown-item(to="/individual-members") Individual Members
          b-dropdown-item(to="/affiliates") Affiliates
          b-dropdown-item(to="/individual-members/changelog") Membership Changes

        b-nav-item-dropdown(right v-if="globalState.isLoggedIn")
          template(slot="button-content")
            fa-icon(icon="wrench")
          b-dropdown-header {{ globalState.username }} - {{ globalState.roleName }}
          b-dropdown-item(to="/card-orders" v-if="globalState.userRole >= 4") Card Order History
          b-dropdown-item(to="/debug" v-if="globalState.userRole >= 5") Debug Tools
          b-dropdown-item(to="/roles" v-if="globalState.userRole >= 4") Manage User Roles
          b-dropdown-item(to="/events/organizers" v-if="globalState.userRole >= 4") Manage Event Organizers
          b-dropdown-item(to="/patchnotes") Patch Notes
          b-dd-divider
          b-dropdown-item(href="/api/auth/logout") Logout

  div.top-nav-spacer
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      globalState: globalState
    }
  }
}
</script>

<style lang="scss">
.top-nav-spacer {
  height: 70px;
}
</style>
