import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/individual-members',
      name: 'Individual Members',
      component: () => import('@/views/IndividualMemberList.vue')
    },
    {
      path: '/individual-members/changelog',
      name: 'Individual Members Changelog',
      component: () => import('@/views/IndividualMemberChangelog.vue')
    },
    {
      path: '/individual-members/new',
      name: 'New Individual Member',
      component: () => import('@/views/IndividualMemberAdd.vue')
    },
    {
      path: '/individual-members/register/',
      name: 'Join IGDA Finland',
      component: () => import('@/views/IndividualMemberAddKioskmode.vue')
    },
    {
      path: '/individual-members/register/:result',
      name: 'Join IGDA Finland - Result',
      component: () => import('@/views/IndividualMemberAddKioskmode.vue')
    },
    {
      path: '/individual-members/get-link',
      name: 'IGDA Finland Profile Link',
      component: () => import('@/views/IndividualMemberGetToken.vue')
    },
    {
      path: '/individual-members/:id/:token/edit',
      name: 'Your IGDA Finland Profile',
      component: () => import('@/views/IndividualMemberEditWithToken.vue')
    },
    {
      path: '/individual-members/:id/:token/renew',
      name: 'Renew IGDA Finland Membership',
      component: () => import('@/views/IndividualMemberRenewWithToken.vue')
    },
    {
      path: '/individual-members/:id/:token',
      name: 'Edit IGDA Finland Profile',
      component: () => import('@/views/IndividualMemberViewWithToken.vue')
    },
    {
      path: '/individual-members/:id/:token/:result',
      name: 'Edit IGDA Finland Profile - result',
      component: () => import('@/views/IndividualMemberViewWithToken.vue')
    },
    {
      path: '/individual-members/:id',
      name: 'Edit Individual Member',
      component: () => import('@/views/IndividualMemberEdit.vue')
    },
    {
      path: '/affiliates',
      name: 'Affiliates',
      component: () => import('@/views/AffiliatesList.vue')
    },
    {
      path: '/affiliates/new',
      name: 'New Affiliate',
      component: () => import('@/views/AffiliatesAdd.vue')
    },
    {
      path: '/affiliates/:id',
      name: 'Edit Affiliate',
      component: () => import('@/views/AffiliatesEdit.vue')
    },
    {
      path: '/membership-check',
      name: 'Membership Check',
      component: () => import('@/views/MembershipCheck.vue')
    },
    {
      path: '/events',
      name: 'Events',
      component: () => import('@/views/EventCheckinList.vue')
    },
    {
      path: '/events/visitors',
      name: 'Event Visitors',
      component: () => import('@/views/VisitorList.vue')
    },
    {
      path: '/events/visitors/:id',
      name: 'Edit Event Visitor',
      component: () => import('@/views/VisitorEdit.vue')
    },
    {
      path: '/events/new-visitor',
      name: 'New Event Visitor',
      component: () => import('@/views/VisitorAdd.vue')
    },
    {
      path: '/events/checkin/kiosk/:id',
      name: 'Event Self Check-in',
      component: () => import('@/views/EventCheckinKiosk.vue')
    },
    {
      path: '/events/checkin/:id',
      name: 'Event Check-in',
      component: () => import('@/views/EventCheckin.vue')
    },
    {
      path: '/events/manage',
      name: 'Event Management',
      component: () => import('@/views/EventList.vue')
    },
    {
      path: '/events/new',
      name: 'New Event',
      component: () => import('@/views/EventAdd.vue')
    },
    {
      path: '/events/edit/:id',
      name: 'Edit Event',
      component: () => import('@/views/EventEdit.vue')
    },
    {
      path: '/events/analytics',
      name: 'Event Analytics',
      component: () => import('@/views/EventAnalytics.vue')
    },
    {
      path: '/events/organizers',
      name: 'Organizers',
      component: () => import('@/views/SystemOrganizers.vue')
    },
    {
      path: '/card-orders',
      name: 'Card Orders',
      component: () => import('@/views/SystemCardOrders.vue')
    },
    {
      path: '/roles',
      name: 'Roles',
      component: () => import('@/views/SystemRoles.vue')
    },
    {
      path: '/patchnotes',
      name: 'Patch Notes',
      component: () => import('@/views/SystemPatchNotes.vue')
    },
    {
      path: '/debug',
      name: 'Debug',
      component: () => import('@/views/SystemDebug.vue')
    },
    {
      path: '/400',
      name: '400',
      component: () => import('@/views/System400.vue')
    },
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/SystemLogin.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  document.title = 'Emmi - ' + to.name
})

export default router
