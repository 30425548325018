// Vue 2 - The magic
import Vue from 'vue'

// Bootstrap 4 - UX framework
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/scss/bootstrap.scss'
import './theme/darkly-bootstrap.min.css'
// import './theme/_variables.scss'
// import './theme/_bootswatch.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Axios - HTTP library
import axios from 'axios'
import VueAxios from 'vue-axios'

// VueCookies - Cookie library
import VueCookies from 'vue-cookies'

// VueNotifications - Toaster library
import Notifications from 'vue-notification'

// Font Awesome - free icon library
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faCalendarCheck, faTags, faWrench, faSpinner, faPlus, faStar, faCheck, faClock, faTimes, faChevronCircleRight, faChevronCircleDown, faCity, faUsers, faUserTag, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Load Script - generic JS runtime injector
import LoadScript from 'vue-plugin-load-script'

// Our own stuff
import router from './router'
import App from '@/App.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DevDataDump from '@/components/DevDataDump.vue'

// Moment - date handling library
Vue.use(require('vue-moment'))

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(Notifications)
Vue.use(LoadScript)

library.add(faCalendarAlt, faCalendarCheck, faTags, faWrench, faSpinner, faPlus, faStar, faCheck, faClock, faTimes, faChevronCircleRight, faChevronCircleDown, faCity, faUsers, faUserTag, faGraduationCap)

// Shared data store object for all components to use
let globalState = {
  isProductionEnv: true,
  isLoggedIn: false,
  userRole: 0,
  username: '',
  roleName: '',
  version: '🤔'
}
export default globalState

// Global components
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('top-navigation', TopNavigation)
Vue.component('loading-spinner', LoadingSpinner)
Vue.component('dev-data-dump', DevDataDump)

Vue.config.productionTip = false

// Turn Vue on and go!
new Vue({
  router,
  render: h => h(App),
  data: function () {
    return {
      globalState
    }
  },
  methods: {
    clearLoggedInState: function () {
      this.globalState.isLoggedIn = false
      this.globalState.username = ''
      this.globalState.userRole = 0
      this.globalState.roleName = ''
    }
  },
  mounted: function () {
    axios.interceptors.response.use((res) => {
      return res
    }, (err) => {
      // On every request response error...
      // Handle session expiration
      if (err.response) {
        if (err.response.status === 401 && err.response.data === 'Not authenticated') {
          this.clearLoggedInState()
          this.$router.push({ path: '/400' })
        } else if (err.response.status === 401) this.$router.push({ path: '/400' })
      }
      return Promise.reject(err)
    })

    Vue.axios.get('/api/state')
      .then(res => {
        this.globalState.isProductionEnv = res.data.env === 'production'
        this.globalState.version = res.data.version

        if (res.data.hasOwnProperty('user')) {
          this.globalState.isLoggedIn = true
          this.globalState.username = res.data.user.name
          this.globalState.userRole = res.data.user.role
          this.globalState.roleName = res.data.user.roleName
        } else {
          this.clearLoggedInState()
        }
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Environment check failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}).$mount('#app')
