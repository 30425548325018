<template lang="pug">
div#loading.container
  div.row.d-flex.justify-content-center
    fa-icon(icon="spinner" size="4x" spin)
  div.row.d-flex.justify-content-center
    h3 {{message}}
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>

<style lang="scss">
#loading {
    margin-top: 50px;
    .row {
        margin-bottom: 20px;
    }
}
</style>
