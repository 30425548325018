<template lang="pug">
div
  top-navigation/

  notifications(group="error" position="top right")/
  notifications(group="success" position="top center")/

  router-view/

  footer
    div.text-center.small Current version: {{globalState.version}} (#[router-link(to="/patchnotes") what's new?])
    div.text-center.small Created with love and understanding by the glorious people of #web-and-tech-projects. <a href="http://gitlab.com/igdafi/emmi" target="_blank" rel="noopener">Help us make Emmi better!</a>
</template>

<script>
import globalState from '@/main.js'

export default {
  data() {
    return {
      globalState
    }
  }
}
</script>

<style lang="sass">
html
  position: relative
  min-height: 100%

body
  /* Margin bottom by footer height + margin */
  margin-bottom: 145px

footer
  position: absolute
  bottom: 0
  width: 100%
  /* Set the fixed height of the footer here */
  height: 45px

h1
    margin-bottom: 20px

h2
    margin-bottom: 20px

h3
    margin-bottom: 20px

.table-row-link
  cursor: pointer

/* Datepicker dark theme fix */
.vdp-datepicker__calendar
  color: black
</style>
